import { Box, Flex, Image } from '@chakra-ui/react';
import Header from '../../components/Header';
import './styles.scss';
export default function Vision() {
  return (
    <>
      <Box w="100%" bgColor="#ECE7E2" pb="50px" overflowX="hidden">
        <Header />
        {/* page1 */}
        <Box h="100vh" w="100%">
          <Flex
            w="100%"
            h="100%"
            justifyContent={{ base: 'center', lg: 'space-between' }}
            alignItems="center"
            flexDir={{ base: 'column', lg: 'row' }}
            gap={{ base: '40px', lg: '0px' }}
          >
            <Flex
              flexDir="column"
              w={{ base: '100%', lg: '50%' }}
              px={{ base: '0px', xl: '150px' }}
              py={{ base: '0px', xl: '250px' }}
              justifyContent="center"
              alignItems="center"
            >
              <Box
                fontSize={{ base: '32px', lg: '40px' }}
                color="#B3701F"
                fontWeight="bold"
                mb="20px"
              >
                Vision
              </Box>
              <Box
                w={{ base: '90%', lg: '60%' }}
                fontSize={{ base: '14px', lg: '18px' }}
                fontWeight="500"
                textAlign={{ base: 'center', lg: 'start' }}
              >
                <p>
                  Libero stands by 3 pivotal pillars that make us who we are.
                </p>
                <p>
                  <strong>- Community</strong>
                </p>

                <p>
                  <strong>- Exclusivity</strong>
                </p>

                <p>
                  <strong>- Flexibility</strong>
                </p>
              </Box>
            </Flex>
            <Box w={{ base: '100%', md: '75%', lg: '50%' }}>
              <Image
                objectFit="cover"
                w="100%"
                h={{ base: '50vh', lg: '60vh' }}
                alt=""
                src="/vision/1.png"
              />
            </Box>
          </Flex>
        </Box>
        {/* page2 */}
        <Box h="100vh" w="100%">
          <Flex
            w="100%"
            h="100%"
            justifyContent={{ base: 'center', lg: 'space-between' }}
            alignItems="center"
            flexDir={{ base: 'column', lg: 'row' }}
            gap={{ base: '20px', lg: '0px' }}
          >
            <Box
              data-aos="fade-right"
              w={{ base: '100%', md: '75%', lg: '50%' }}
            >
              <Image
                objectFit="cover"
                w="100%"
                h={{ base: '50vh', lg: '60vh' }}
                alt=""
                src="/vision/2.png"
              />
            </Box>
            <Flex
              data-aos="fade-left"
              flexDir="column"
              w={{ base: '100%', lg: '50%' }}
              px={{ base: '0px', xl: '150px' }}
              py={{ base: '0px', xl: '250px' }}
              justifyContent="center"
              alignItems="center"
            >
              <Box
                fontSize={{ base: '30px', md: '32px', lg: '40px' }}
                my={{ base: '10px', lg: '20px' }}
                color="#B3701F"
                fontWeight="bold"
              >
                Community
              </Box>
              <Box
                w={{ base: '80%', md: '50%', lg: '70%', xl: '100%' }}
                fontSize={{ base: '14px', lg: '18px' }}
                fontWeight="400"
              >
                Community is the core of Libero. We envision our community to
                create long lasting friendships, be able to network to create
                opportunities. This enables the community to share personal
                relatedness and support perpetual growth of each other,
                ourselves and our environment.
              </Box>
            </Flex>
          </Flex>
        </Box>
        {/* Page 3 */}
        <Box h="100vh" w="100%">
          <Flex
            w="100%"
            h="100%"
            justifyContent={{ base: 'center', lg: 'space-between' }}
            alignItems="center"
            flexDir={{ base: 'column', lg: 'row' }}
            gap={{ base: '20px', lg: '0px' }}
          >
            <Flex
              data-aos="fade-right"
              flexDir="column"
              w={{ base: '100%', lg: '50%' }}
              px={{ base: '0px', xl: '150px' }}
              py={{ base: '0px', xl: '250px' }}
              justifyContent="center"
              alignItems="center"
            >
              <Box
                fontSize={{ base: '30px', md: '32px', lg: '40px' }}
                my={{ base: '10px', lg: '20px' }}
                color="#B3701F"
                fontWeight="bold"
              >
                Exclusivity
              </Box>
              <Box
                w={{ base: '80%', md: '50%', lg: '70%', xl: '100%' }}
                fontSize={{ base: '14px', lg: '18px' }}
                fontWeight="400"
              >
                Exclusivity is pivotal to the success and future of Libero.
                Exclusivity elicits many rewards including a sense of belonging
                and importance. Libero will remain a family, those that are part
                of the family will receive access to our exclusive ecosystem. In
                order to grow we have set goals and aspire to make great impacts
                in both Web 2 & Web 3.
              </Box>
            </Flex>
            <Box
              data-aos="fade-left"
              w={{ base: '100%', md: '75%', lg: '50%' }}
            >
              <Image
                objectFit="cover"
                w="100%"
                h={{ base: '50vh', lg: '60vh' }}
                alt=""
                src="/vision/3.png"
              />
            </Box>
          </Flex>
        </Box>
        {/* page 4 */}
        <Box h="100vh" w="100%">
          <Flex
            w="100%"
            h="100%"
            justifyContent={{ base: 'center', lg: 'space-between' }}
            alignItems="center"
            flexDir={{ base: 'column', lg: 'row' }}
            gap={{ base: '20px', lg: '0px' }}
          >
            <Box
              data-aos="fade-right"
              w={{ base: '100%', md: '75%', lg: '50%' }}
            >
              <Image
                objectFit="cover"
                w="100%"
                h={{ base: '50vh', lg: '60vh' }}
                alt=""
                src="/vision/4.png"
              />
            </Box>
            <Flex
              data-aos="fade-left"
              flexDir="column"
              w={{ base: '100%', lg: '50%' }}
              px={{ base: '0px', xl: '150px' }}
              py={{ base: '0px', xl: '250px' }}
              justifyContent="center"
              alignItems="center"
            >
              <Box
                fontSize={{ base: '30px', md: '32px', lg: '40px' }}
                my={{ base: '10px', lg: '20px' }}
                color="#B3701F"
                fontWeight="bold"
              >
                Flexibility
              </Box>
              <Box
                w={{ base: '80%', md: '50%', lg: '70%', xl: '100%' }}
                fontSize={{ base: '14px', lg: '18px' }}
                fontWeight="400"
              >
                Libero believes in "No Roadmap" but rather being flexible.
                Businesses that are flexible and agile survive many obstacles.
                Those that are not only able to quickly adapt to changes, but
                also reimagine the experiences with the right toolset, fared
                even better. Focusing on business resilience and increasing
                flexibility will enable Libero to succeed in an expanding,
                shifting work environment, as well as to be better positioned
                for when the next unexpected disruption comes our way.
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
