import {
  Box,
  Flex,
  Hide,
  Image,
  Show,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Modal } from '@chakra-ui/react';

export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isOpenIcon, setIsOpenIcon] = useState(true);

  const handleOpenButton = useCallback(() => {
    setIsOpenIcon(!isOpenIcon);
    onOpen();
  }, [isOpenIcon, onOpen]);

  const handleCloseButton = useCallback(() => {
    onClose();
    setIsOpenIcon(!isOpenIcon);
  }, [isOpenIcon, onClose]);

  return (
    <Box position="fixed" zIndex="10" w="100%" bgColor="hsla(0,0%,100%,.7)">
      <Flex
        px={{ base: '16px', md: '30px' }}
        justifyContent={{ base: 'right', lg: 'center' }}
        mr={{ base: '10px', md: '0px' }}
        gap="100px"
        alignItems="center"
        p={{ base: '2px 0px', md: '12px', lg: '0px' }}
      >
        <Hide below="md">
          <Flex
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            px="40px"
          >
            <Link to="/">
              <Box>
                <Image w="165px" h="80px" alt="" src="/header/logo1.png" />
              </Box>
            </Link>
            <Flex alignItems="center" justifyContent="center">
              <Flex mr="20px" gap="12px">
                <Link to="/about">
                  <Box cursor="pointer" position="relative">
                    <Image
                      borderRadius="24px"
                      w="130px"
                      h="80px"
                      alt=""
                      src="/header/woodframe.png"
                      objectFit="cover"
                    />
                    <Text
                      top="22px"
                      mt="0.625rem"
                      position="absolute"
                      fontWeight="900"
                      color="#FFF"
                      w="100%"
                      fontSize="13px"
                      lineHeight="1.5"
                      textAlign="center "
                    >
                      ABOUT
                    </Text>
                  </Box>
                </Link>

                <Link to="/vision">
                  <Box cursor="pointer" position="relative">
                    <Image
                      borderRadius="24px"
                      w="130px"
                      h="80px"
                      alt=""
                      src="/header/woodframe.png"
                      objectFit="cover"
                    />
                    <Text
                      top="22px"
                      mt="0.625rem"
                      position="absolute"
                      fontWeight="900"
                      color="#FFF"
                      w="100%"
                      fontSize="13px"
                      lineHeight="1.5"
                      textAlign="center "
                    >
                      VISION
                    </Text>
                  </Box>
                </Link>

                <Link to="/gallery">
                  <Box cursor="pointer" position="relative">
                    <Image
                      borderRadius="24px"
                      w="130px"
                      h="80px"
                      alt=""
                      src="/header/woodframe.png"
                      objectFit="cover"
                    />
                    <Text
                      top="22px"
                      mt="0.625rem"
                      position="absolute"
                      fontWeight="900"
                      color="#FFF"
                      w="100%"
                      fontSize="13px"
                      lineHeight="1.5"
                      textAlign="center "
                    >
                      GALLERY
                    </Text>
                  </Box>
                </Link>
              </Flex>
              <Flex right="20px" gap="16px">
                <Link
                  to="https://discord.gg/gkKPnWN4e5"
                  target="_blank"
                  isExternal
                >
                  <Box cursor="pointer">
                    <Image
                      alt=""
                      w="40px"
                      h="40px"
                      src="/header/discord.png"
                      borderRadius="20px"
                      objectFit="cover"
                    />
                  </Box>
                </Link>
                <Link
                  to="https://twitter.com/LiberoNFT"
                  target="_blank"
                  isExternal
                >
                  <Box cursor="pointer">
                    <Image
                      alt=""
                      w="40px"
                      h="40px"
                      src="/header/twitter.jpeg"
                      borderRadius="20px"
                      objectFit="cover"
                    />
                  </Box>
                </Link>
                <Hide>
                  <Link
                    to="https://opensea.io/collection/khuga-by-khuga-labs"
                    isExternal
                    target="_blank"
                  >
                    <Box cursor="pointer">
                      <Image
                        alt=""
                        w="40px"
                        h="40px"
                        src="/header/opensea.png"
                        borderRadius="20px"
                        objectFit="cover"
                      />
                    </Box>
                  </Link>
                </Hide>
              </Flex>
            </Flex>
          </Flex>
        </Hide>
        <Show below="md">
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            <Link to="/">
              <Box>
                <Image w="120px" h="64px" alt="" src="/header/logo1.png" />
              </Box>
            </Link>
            <Box position="relative">
              <Image
                border="1px solid #ccc"
                borderRadius="7px"
                w="46px"
                h="44px"
                alt=""
                src="/header/vuong.png"
              />
              {isOpenIcon ? (
                <Box onClick={handleOpenButton}>
                  <HamburgerIcon
                    w="28px"
                    top="2"
                    left="2"
                    h="28px"
                    position="absolute"
                    color="#FFF"
                  />
                </Box>
              ) : (
                <Box onClick={handleCloseButton}>
                  <CloseIcon
                    w="18px"
                    top="3"
                    left="3.5"
                    h="18px"
                    position="absolute"
                    color="#FFF"
                  />
                </Box>
              )}
            </Box>
          </Flex>
        </Show>
      </Flex>
      <Box>
        <Modal
          mt="20px"
          size="full"
          isOpen={isOpen}
          onClose={handleCloseButton}
        >
          <Box
            w="100%"
            h="100%"
            bgColor="hsla(0,0%,100%,.7)"
            top="66px"
            position="absolute"
          >
            <Flex
              mt="48px"
              justifyContent="center"
              flexDir="column"
              alignItems="center"
              gap="12px"
            >
              <Link to="/about">
                <Box cursor="pointer" position="relative">
                  <Image
                    w="210px"
                    h="56px"
                    alt=""
                    src="/header/woodframe.png"
                    objectFit="cover"
                  />
                  <Text
                    top="5px"
                    mt="0.75rem"
                    position="absolute"
                    fontWeight="600"
                    color="#FFF"
                    w="100%"
                    fontSize="16px"
                    lineHeight="1.5"
                    textAlign="center "
                  >
                    ABOUT
                  </Text>
                </Box>
              </Link>
              <Link to="/vision">
                <Box cursor="pointer" position="relative">
                  <Image
                    w="210px"
                    h="56px"
                    alt=""
                    src="/header/woodframe.png"
                    objectFit="cover"
                  />
                  <Text
                    top="5px"
                    mt="0.75rem"
                    position="absolute"
                    fontWeight="600"
                    color="#FFF"
                    w="100%"
                    fontSize="16px"
                    lineHeight="1.5"
                    textAlign="center "
                  >
                    VISION
                  </Text>
                </Box>
              </Link>

              <Link to="/gallery">
                <Box cursor="pointer" position="relative">
                  <Image
                    w="210px"
                    h="56px"
                    alt=""
                    src="/header/woodframe.png"
                    objectFit="cover"
                  />
                  <Text
                    top="5px"
                    mt="0.75rem"
                    position="absolute"
                    fontWeight="600"
                    color="#FFF"
                    w="100%"
                    fontSize="16px"
                    lineHeight="1.5"
                    textAlign="center "
                  >
                    GALLERY
                  </Text>
                </Box>
              </Link>
            </Flex>
            <Flex
              mt="24px"
              alignItems="center"
              justifyContent="center"
              gap="12px"
            >
              <Link
                to="https://discord.gg/gkKPnWN4e5"
                target="_blank"
                isExternal
              >
                <Box cursor="pointer">
                  <Image
                    alt=""
                    w="46px"
                    h="44px"
                    borderRadius="30px"
                    objectFit="cover"
                    src="/header/discord.png"
                  />
                </Box>
              </Link>
              <Link
                to="https://twitter.com/LiberoNFT"
                target="_blank"
                isExternal
              >
                <Box cursor="pointer">
                  <Image
                    alt=""
                    w="46px"
                    h="44px"
                    borderRadius="30px"
                    objectFit="cover"
                    src="/header/twitter.jpeg"
                  />
                </Box>
              </Link>
            </Flex>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}
