import { Box, Text } from '@chakra-ui/react';

export default function Footer() {
  return (
    <Box
      w="100%"
      position="fixed"
      bottom="0"
      py="16px"
      bgColor="#FFF"
      textAlign="center"
    >
      <Text fontSize="0.75rem" color="#000">
        @2023 Libero NFT by Libero Labs
      </Text>
    </Box>
  );
}
