export default function Icon4() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current w-6"
        fill="none"
        viewBox="0 0 172 85"
      >
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M22.276 2.892C16.693 5.643 11.759 14.034 9.795 24.12c-1.86 9.552-1.358 8.9-5.61 7.284-5-1.901-4.6.139 2.358 12.024 11.935 20.388 32.74 33.3 65.304 40.526l3.847.854-.882-7.794c-.485-4.287-1.971-12.364-3.301-17.949-2.257-9.472-2.267-10.06-.142-8.733 4.352 2.717 7.055.212 6.25-5.793-1.137-8.474-9.12-14.627-10.645-8.206-.435 1.831-.885 1.53-2.09-1.398C53.053 6.167 38.237-4.975 22.277 2.892zm108.327-.351c-7.565 3.295-16.704 15.898-23.484 32.385-1.206 2.933-1.658 3.237-2.093 1.407-1.526-6.421-9.508-.268-10.645 8.206-.805 6.005 1.898 8.51 6.25 5.793 2.125-1.327 2.115-.739-.142 8.733-1.33 5.585-2.816 13.662-3.301 17.949l-.882 7.794 3.847-.854c32.563-7.226 53.369-20.138 65.304-40.526 6.957-11.885 7.357-13.925 2.358-12.024-4.252 1.616-3.75 2.268-5.61-7.284-3.727-19.139-16.765-28.042-31.602-21.579zM36.247 8.755c6.512 2.468 15.495 12.466 9.88 10.998-3.015-.789-5.01 1.897-2.944 3.963 3.73 3.73-.342 12.951-5.72 12.951-1.677 0-4.15-1.215-5.492-2.699-5.37-5.932-7.64 11.552-2.775 21.365L31.509 60l-5.97-6.13C12.649 40.63 14.08 14.292 28 8.594c3.607-1.476 3.942-1.47 8.248.161zm111.608 2.349c10.597 9.369 9.885 31.182-1.395 42.766L140.491 60l2.313-4.667c4.865-9.813 2.594-27.297-2.775-21.365-1.343 1.484-3.815 2.699-5.493 2.699-5.377 0-9.449-9.221-5.719-12.951 2.066-2.066.071-4.752-2.944-3.963-5.282 1.381 3.152-8.38 9.461-10.95 4.037-1.645 9.113-.712 12.521 2.301zM66.433 70.913l.799 4.246-6.95-2.049c-8.64-2.549-8.74-2.651-3.647-3.77 2.287-.502 4.865-2.044 5.728-3.426 1.807-2.894 2.817-1.653 4.07 4.999zm48.997-1.558c5.025 1.103 4.92 1.208-3.771 3.772l-7.008 2.067.798-3.597c1.953-8.796 1.94-8.777 4.013-5.818 1.031 1.472 3.717 3.081 5.968 3.576z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </>
  );
}
