export default function Icon2() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current w-6"
        fill="none"
        viewBox="0 0 159 141"
      >
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M0 51v50.667h29.733c21.348 0 30.185.451 31.334 1.6 1.244 1.244 1.955 1.244 3.2 0 1.084-1.084 7.318-1.6 19.333-1.6h17.733V47h10c9.111 0 10-.237 10-2.667 0-2.429-.889-2.666-10-2.666h-10V.333H0V51zm92 0v41.333H65.473l-.403-7c-.297-5.154-.931-7-2.403-7-1.473 0-2.107 1.846-2.403 7l-.403 7H9.333V9.667H92V51zm-32 1.426c0 10.395.102 10.745 3 10.333 2.609-.37 3.052-1.329 3.401-7.358l.4-6.933 6.933-.401c6.029-.348 6.988-.791 7.359-3.4.411-2.899.062-3-10.334-3H60v10.759zm77.574-7.759c.371 2.613 1.328 3.052 7.426 3.403l7 .403v7.263c0 6.859.186 7.264 3.333 7.264 3.278 0 3.334-.178 3.334-10.667V41.667h-10.76c-10.395 0-10.745.101-10.333 3zm16.327 34.778c-1.399 3.645.064 23.996 1.766 24.559 1.259.417 1.666-2.732 1.666-12.893 0-12.309-1.382-17.009-3.432-11.666zM60 129.574v10.759h10.759c10.396 0 10.745-.101 10.334-3-.371-2.609-1.33-3.052-7.359-3.4l-6.933-.401-.4-6.933c-.349-6.029-.792-6.988-3.401-7.358-2.898-.412-3-.062-3 10.333zm92.889-9.685c-.489.489-.889 3.757-.889 7.264v6.374l-7 .403c-6.098.351-7.055.79-7.426 3.403-.412 2.899-.062 3 10.333 3h10.76v-10.666c0-10.509-1.875-13.681-5.778-9.778zm-56.222 16.444c-1.842 2.981 1.407 4 12.745 4 11.032 0 11.921-.199 11.921-2.666 0-2.961-22.896-4.199-24.666-1.334z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </>
  );
}
