import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Show,
  Text,
  useDisclosure,
  Hide,
} from '@chakra-ui/react';
import Icon1 from './IconFilter/icon1';
import Icon2 from './IconFilter/icon2';
import Icon3 from './IconFilter/icon3';
import Icon4 from './IconFilter/icon4';
import Icon5 from './IconFilter/icon5';
import Icon6 from './IconFilter/icon6';
import Icon7 from './IconFilter/icon7';
export default function GridtItem() {
  const listitem = [
    {
      src: '/gallery/gridnft/nft1.jpg',
      color: 'BE4BB6',
      name: 'Libero #1',
    },
    {
      src: '/gallery/gridnft/nft2.jpg',
      color: 'F85B66',
      name: 'Libero #2',
    },
    {
      src: '/gallery/gridnft/nft3.jpg',
      color: 'F9A844',
      name: 'Libero #3',
    },
    {
      src: '/gallery/gridnft/nft4.jpg',
      color: 'A65FD0',
      name: 'Libero #4',
    },
    {
      src: '/gallery/gridnft/nft5.jpg',
      color: 'ADCC64',
      name: 'Libero #5',
    },
    {
      src: '/gallery/gridnft/nft6.jpg',
      color: 'CC69B4',
      name: 'Libero #6',
    },
    {
      src: '/gallery/gridnft/nft7.jpg',
      color: '4F7ABD',
      name: 'Libero #7',
    },
    {
      src: '/gallery/gridnft/nft8.jpg',
      color: '7966C9',
      name: 'Libero #8',
    },
    {
      src: '/gallery/gridnft/nft9.jpg',
      color: '4F7ABD',
      name: 'Libero #9',
    },
  ];
  const listmodal = [
    {
      icon: [<Icon1 />],
      name: 'Race',
      text: 'SPIN-X',
      placeholder: 'Search Race',
    },

    {
      icon: [<Icon2 />],
      name: 'Background',
      text: 'KIMONO',
      placeholder: 'Search Background',
    },
    {
      icon: [<Icon3 />],
      name: 'Cloth',
      text: 'MAD',
      placeholder: 'Search Cloth',
    },
    {
      icon: [<Icon4 />],
      name: 'Eyes',
      text: 'BOBA TEA',
      placeholder: 'Search Eyes',
    },
    {
      icon: [<Icon5 />],
      name: 'Hand',
      text: 'PUNK',
      placeholder: 'Search Hand',
    },
    {
      icon: [<Icon6 />],
      name: 'Head',
      text: 'CIGARETTE',
      placeholder: 'Search Head',
    },
    {
      icon: [<Icon7 />],
      name: 'Mouth',
      text: 'GREEN',
      placeholder: 'Search Mouth',
    },
  ];
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Grid
        templateColumns={{
          base: 'repeat(2, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
          xl: 'repeat(3, 1fr)',
        }}
        gap={{ base: 4, md: 6, lg: 10 }}
      >
        {listitem.map((item, idx) => (
          <GridItem onClick={onOpen} key={idx}>
            <Flex
              flexDir="column"
              alignItems="center"
              borderRadius="8px"
              justifyContent="center"
            >
              <Image
                border="1px solid rgba(0,0,0,0.75)"
                background="#FFF"
                boxShadow="0px 0px 10px 0px rgba(0,0,0,0.75)"
                objectFit="cover"
                borderRadius="8px"
                alt=""
                src={item.src}
                w={{ base: '250px', xl: '320px', '2xl': '540px' }}
                h={{ base: '200px', md: '250px', xl: '320px', '2xl': '420px' }}
              />
              <Box p="2px 0px" fontWeight="500" textAlign="center">
                <Text>{item.name}</Text>
              </Box>
            </Flex>
          </GridItem>
        ))}
      </Grid>

      <Hide>
        <Modal
          size={{ base: 'sm', md: '2xl', lg: '3xl', xl: '6xl' }}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent position="relative" bgColor="rgba(139,0,0,0.7)">
            <ModalCloseButton
              color="#FFF"
              position="absolute"
              borderRadius="20px"
              top="-12px"
              right="-12px"
              w={{ base: '32px', lg: '36px', xl: '40px' }}
              h={{ base: '32px', lg: '36px', xl: '40px' }}
            >
              <Image
                alt=""
                borderRadius="14px"
                src="/gallery/gridnft/nft7.jpg"
              />
            </ModalCloseButton>
            <ModalBody>
              <Flex
                direction={{ base: 'column', md: 'row' }}
                justifyContent={{ base: 'center', md: 'space-between' }}
                alignItems="center"
              >
                <Flex
                  mr={{ md: '5px', lg: '10px', xl: '20px' }}
                  w={{ md: '50%' }}
                  justifyContent="center"
                  mb="4"
                  position="relative"
                >
                  <Show below="sm">
                    <Image
                      borderRadius="10px"
                      alt=""
                      w="200px"
                      h="200px"
                      src="/gallery/tablewood.png"
                    />
                  </Show>
                  <Image
                    top="10px"
                    position={{ base: 'absolute', md: 'unset' }}
                    objectFit="cover"
                    w={{ base: '180px', md: '300px', lg: '360px', xl: '460px' }}
                    h={{ base: '180px', md: '300px', lg: '360px', xl: '460px' }}
                    alt=""
                    src="/gallery/gridnft/nft2.webp"
                    boxShadow={{ md: '0px 0px 10px 0px rgba(0,0,0,0.75)' }}
                    border="4px solid transparent"
                    borderRadius="10px"
                    background={{ md: 'rgb(144,56,5)' }}
                  />
                </Flex>
                <Box
                  w={{ md: '50%' }}
                  textAlign="center"
                  fontWeight="900"
                  maxW="90%"
                >
                  <Box
                    bgColor="rgb(144,56,5)"
                    borderRadius="4"
                    mb="4"
                    p="2"
                    display="flex"
                    border="1px solid #ccc"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text color="#FFF" fontSize="lg">
                      Libero #1
                    </Text>
                    <Link
                      to="https://opensea.io/collection/khuga-by-khuga-labs"
                      isExternal
                    >
                      <Box cursor="pointer">
                        <Image
                          alt=""
                          w="24px"
                          h="24px"
                          borderRadius="30px"
                          objectFit="cover"
                          src="/header/opensea.png"
                        />
                      </Box>
                    </Link>
                  </Box>
                  <Box mb="4">
                    <Grid templateColumns="1fr 1fr" gap="2" alignItems="center">
                      {listmodal.map((item, idx) => (
                        <Box
                          key={idx}
                          borderRadius="4"
                          border="1px solid #ccc"
                          bgColor="rgb(144,56,10)"
                          p="2"
                          display="flex"
                          gap="2"
                          alignItems="center"
                          color="#FFF"
                        >
                          <Box color="#FFF" w="24px" h="16px">
                            {item.icon}
                          </Box>
                          <Flex
                            flexDir="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                          >
                            <Text fontSize="sm">{item.name}</Text>
                            <Text fontSize="md">{item.text}</Text>
                          </Flex>
                        </Box>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Hide>
    </>
  );
}
