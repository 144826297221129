import { ChakraProvider } from "@chakra-ui/react";
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Home from "./pages/Home";
import Vision from "./pages/Vision";


function App() {
  return (
    <ChakraProvider>
       <Router>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/vision" element={<Vision/>} />
          <Route path="/gallery" element={<Gallery/>} />
        </Routes>
      </Router>
      
    </ChakraProvider>
  );
}

export default App; 
