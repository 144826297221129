import { Box, Flex, Hide, Image, Show } from '@chakra-ui/react';
import React from 'react';
import Header from '../../components/Header';
import ListTeam from '../../components/ListTeam/index.';

export default function About() {
  return (
    <Box h="100%" bgColor="#ECE7E2">
      <Header />
      <Box mb="80px">
        <Flex
          mb={{ base: '0px', md: '20px' }}
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            mt={{ base: '25%', md: '6%' }}
            fontSize={{ base: '28px', md: '36px' }}
            fontWeight="900"
            color="#0f0f0f"
          >
            About
          </Box>
          <Box
            mb="10px"
            color="#e16428"
            fontSize={{ base: '20px', md: '24px' }}
          >
            LIBERO
          </Box>
          <Box
            w={{ base: '80%', md: '65%', '2xl': '40%' }}
            fontSize={{ base: '14px', md: '18px' }}
          >
            Libero derives from the Italian word Freedom. Libero is an Exclusive
            400 Supply collection based on Roman Gladiators. Our core values
            stem from “Freedom”, meaning no one gets left behind. We treat our
            community like family, treating everyone with equality, building
            real & lasting connections which will make us a strong & ambitious
            project.
          </Box>
        </Flex>

        <Hide below="sm">
          <Flex
            justifyContent="center"
            alignItems="center"
            mx="auto"
            w={{ lg: '90%', xl: '100%' }}
          >
            <Image
              h={{ md: '400px', lg: '500px', xl: '650px' }}
              borderRadius="10px"
              objectFit="cover"
              alt=""
              src="/about/about.jpg"
            />
          </Flex>
        </Hide>

        <Show below="sm">
          <Flex justifyContent="center" mx="20px">
            <Image
              borderRadius="10px"
              objectFit="cover"
              alt=""
              src="/about/aboutmobile.png"
            />
          </Flex>
        </Show>
      </Box>
      <Flex
        justifyContent="center"
        bgColor="#ccc"
        p={{ base: '0px 20px 50px 20px', lg: '20px 50px 50px 50px' }}
      >
        <ListTeam />
      </Flex>
    </Box>
  );
}
