export default function Icon1() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current w-6"
        fill="none"
        viewBox="0 0 219 155"
      >
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M3.039 1.712c-6.205 16.172-2.02 52.792 8.335 72.926 2.943 5.722 3.483 8.634 3.716 20.029.896 43.807 25.701 60 91.91 60 65.365 0 90-16.283 90-59.488 0-6.476.635-8.856 3.29-12.336 9.202-12.065 14.414-31.867 17.388-66.056l.859-9.879-21.248.876c-11.687.482-28.631 2.13-37.655 3.662l-16.406 2.787-9.447-3.117c-12.005-3.96-43.636-4.389-54.674-.742-6.198 2.048-6.981 2.026-14.173-.396C47.839 4.219 4.308-1.594 3.039 1.712zm43.294 15.089c11.362 2.305 11.462 2.685 2.447 9.243-9.92 7.216-19.496 18.748-25.216 30.367l-4.248 8.629-1.91-4.52c-4.586-10.855-8.175-44.973-5.058-48.09.754-.754 22.522 2.046 33.985 4.371zm85.174 5.63c36.798 12.798 59.964 48.607 53.524 82.738-5.588 29.614-31.178 41.158-86.639 39.083-49.428-1.849-70.196-15.25-71.778-46.317-2.73-53.577 52.867-93.597 104.893-75.504zM206.328 21c-.008 4.478-3.855 27.647-5.479 33-4.052 13.354-7.846 20.282-7.85 14.333-.005-7.462-16.295-31.481-26.747-39.436-3.539-2.694-6.468-5.28-6.509-5.747-.08-.894 20.759-3.557 33.257-4.25 4.033-.224 8.683-.518 10.333-.653 2.297-.189 2.999.456 2.995 2.753zM94.196 101.843c-4.09 3.312-1.966 8.53 4.78 11.747 9.001 4.293 3.787 13.612-6.532 11.676-5.264-.987-6.574 1.012-2.198 3.354 3.602 1.928 9.779 1.72 12.716-.427 2.085-1.525 3.225-1.512 7.284.082 3.585 1.408 5.74 1.538 8.45.507 4.558-1.733 5.153-6.233.637-4.823-6.874 2.148-11.666-.034-11.666-5.311 0-2.123 1.004-3.278 3.367-3.871 8.22-2.063 11.755-11.656 5.213-14.143-6.017-2.288-18.584-1.598-22.051 1.209z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </>
  );
}
