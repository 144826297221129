export default function Icon6() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current w-6"
        fill="none"
        viewBox="0 0 195 132"
      >
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M111 2.977c-3.667.618-11.948 1.175-18.403 1.24-45.225.45-64.701 19.525-65.32 63.976l-.22 15.798-9.251 5.171C3.399 97.216-.698 101.626.403 107.899 3.235 124.038 14.209 130.904 31 127.041c20.9-4.808 48.556-4.188 69.796 1.564 26.156 7.084 39.625.925 81.1-37.085l12.23-11.208-.666-10.323C189.901 14.88 164.229-5.983 111 2.977zm45.563 13.599c15.864 5.838 25.726 25.764 25.76 52.049l.01 7.624L168 89.034c-30.516 27.22-44.728 34.454-59.353 30.211-22.802-6.614-50.3-7.966-75.21-3.698-15.304 2.622-17.692 2.079-20.481-4.653-2.18-5.26-1.033-6.264 18.504-16.207l13.793-7.02 16.873.053c19.214.061 26.292 1.339 49.188 8.876 17.711 5.831 20.772 5.843 20.109.078-1.144-9.942-62.06-22.44-86.423-17.732-7.68 1.485-7.372 2.065-7.007-13.194.925-38.629 14.624-49.231 66.363-51.362C144.701 12.725 161 26.265 161 61.443c0 2.865.643 4.224 2 4.224 2.425 0 2.617-6.072.588-18.58-2.651-16.341-8.677-25.683-20.588-31.918l-5.333-2.792 6 .83c3.3.457 9.103 1.973 12.896 3.369zM61.601 39.542c-11.733 5.111-15.288 19.705-6.854 28.139 14.278 14.277 42.232 6.175 42.248-12.245.012-13.663-19.944-22.624-35.394-15.894zm21.108 3.941c14.245 6.029 12.163 22.048-3.375 25.96-16.345 4.115-30.866-9.293-22.114-20.42 5.404-6.87 16.599-9.303 25.489-5.54z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </>
  );
}
