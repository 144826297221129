import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';

import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  Hide,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import Header from '../../components/Header';
import Filter from './filter';
import GridtItem from './gridtitem';

export default function Gallery() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  return (
    <Box bgColor="#ECE7E2" pb="100px">
      <Header />
      <Box pt={{ base: '60px', md: '100px' }} color="000">
        <Flex p={{ base: '50px 10px 10px 10px', lg: '40px' }}>
          {/* Filter desktop */}
          {/* <Hide>
            <Box
              mr="20px"
              w="25%"
              maxHeight="85vh"
              borderRadius="12px"
              border="1px solid #ccc"
            >
              <Box
                maxHeight="83vh"
                overflow="scroll"
                borderRadius="0.75rem"
                p="24px"
              >
                <Box>
                  <Text
                    fontWeight="900"
                    letterSpacing="-.01em"
                    fontSize="30px"
                    lineHeight="1.5"
                  >
                    Filter
                  </Text>
                  <Flex border="1px solid #D1D5DB">
                    <InputGroup>
                      <InputLeftElement
                        children={<SearchIcon color="gray" />}
                      />
                      <Input placeholder="Search" />
                    </InputGroup>
                  </Flex>
                </Box>
                <Filter />
              </Box>
            </Box>
          </Hide> */}
          <Box w={{ base: '100%', md: '80%', lg: '90%' }} m="auto">
            {/* Low-Hight desktop */}
            {/* <Hide>
              <Flex
                border="2px solid red"
                fontSize="14px"
                mb="24px"
                justifyContent="right"
              >
                <Menu>
                  <MenuButton bgColor="#333232" p="0px" as={Button}>
                    <Button p="0px" bgColor="#333232">
                      <Image
                        position="relative"
                        src="/header/woodframe.png"
                        alt=""
                        borderRadius="24px"
                        w={{ base: '185px', lg: '200px' }}
                        h={{ base: '44px', md: '50px' }}
                        objectFit="cover"
                      />
                      <Flex
                        position="absolute"
                        gap="5px"
                        color="#FFF"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text
                          fontSize="14px"
                          fontWeight={{ base: '700', lg: '900' }}
                        >
                          ID : Low - High
                        </Text>
                        <ChevronDownIcon />
                      </Flex>
                    </Button>
                  </MenuButton>
                  <MenuList color="#000" p="10px" minWidth="170px">
                    <MenuItem cursor="pointer" justifyContent="center">
                      ID: low to high
                    </MenuItem>
                    <MenuItem
                      cursor="pointer"
                      m="5px 0px"
                      justifyContent="center"
                    >
                      ID: high to low
                    </MenuItem>
                    <MenuItem cursor="pointer" mb="5px" justifyContent="center">
                      Rank: low to high
                    </MenuItem>
                    <MenuItem cursor="pointer" justifyContent="center">
                      Rank: high to low
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </Hide> */}
            <GridtItem />
          </Box>

          {/* Filter mobile */}
          {/* <Hide>
            <Button
              p="14px 28px"
              borderRadius="12px"
              boxShadow="rgb(0 0 0 / 8%) 0px 7px 14px, rgb(0 0 0 / 8%) 0px 3px 6px"
              position="fixed"
              cursor="pointer"
              bottom="20px"
              left={{ base: '34%', sm: '36%', md: '42%' }}
              fontWeight="500"
              ref={btnRef}
              bgColor="rgb(252, 213, 53)"
              onClick={onOpen}
            >
              <Flex justifyContent="center" alignItems="center">
                <Image
                  mr="4px"
                  src="/gallery/filter/iconFilter.png"
                  w="18px"
                  h="18px"
                  alt=""
                />
                <Text
                  fontSize="16px"
                  fontFamily="inherit"
                  color="rgb(24, 26, 32)"
                >
                  Filter
                </Text>
              </Flex>
            </Button>
            <Drawer
              size="sm"
              isOpen={isOpen}
              placement="left"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerBody p="0px">
                  <Box
                    mr={{ base: '0px', lg: '20px' }}
                    w={{ base: '100%', lg: '25%' }}
                    height="100%"
                    bgColor="#FFF"
                  >
                    <Box height="100%" borderRadius="0.75rem" p="24px">
                      <Text
                        fontWeight="900"
                        letterSpacing="-.01em"
                        fontSize="30px"
                        lineHeight="1.5"
                      >
                        Filter
                      </Text>
                      <Flex border="1px solid #D1D5DB">
                        <InputGroup>
                          <InputLeftElement
                            children={<SearchIcon color="gray" />}
                          />
                          <Input placeholder="Search" />
                        </InputGroup>
                      </Flex>
                      <Filter />
                    </Box>
                  </Box>
                </DrawerBody>

                <DrawerFooter>
                  <Button background="none" onClick={onClose}>
                    <Image
                      position="relative"
                      w="150px"
                      h="80px"
                      alt=""
                      borderRadius="20px"
                      src="/header/woodframe.png"
                    />
                    <Text
                      fontSize="18px"
                      fontWeight="700"
                      color="#FFF"
                      position="absolute"
                    >
                      Cancel
                    </Text>
                  </Button>
                  <Button background="none">
                    <Image
                      position="relative"
                      w="150px"
                      h="80px"
                      alt=""
                      src="/header/woodframe.png"
                      borderRadius="20px"
                    />
                    <Text
                      fontSize="18px"
                      fontWeight="700"
                      color="#FFF"
                      position="absolute"
                    >
                      Update
                    </Text>
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          </Hide> */}
        </Flex>
      </Box>
    </Box>
  );
}
