import { Box, Flex, Image, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import Footer from '../../components/footer';
import Header from '../../components/Header';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
export default function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);
  return (
    <>
      <Box
        h="100vh"
        bgImage={{ base: '/home/bgmobile.jpg', md: '/home/homemain.jpg' }}
        bgSize="cover"
        bgPosition="center"
      >
        <Header />
        <Footer />
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent
          justifyContent="center"
          alignItems="center"
          bgColor="#000"
          position="relative"
        >
          <ModalCloseButton color="#FFF" />

          <ModalBody p="0px" alignItems="center" position="absolute">
            <iframe
              width="1080"
              height="500"
              src="https://www.youtube.com/embed/IhfY6gGbF8U?autoplay=1"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
