import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { Grid, GridItem } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function ListTeam() {
  const listitem = [
    {
      src: '/about/team1.jpeg',
      color: 'BE4BB6',
      name: 'Peppe - Founder',
      to: 'https://twitter.com/PaccioPeppe',
      from: 'Master Wong "the Shiro Demon" Kobayashi, a cat lover, one of the founders, and also the technical lead. I am a seasoned IT guy with 12+ years of experience in the field of Application Engineering, Project Management, and Disaster Recovery.',
    },
    {
      src: '/about/team2.jpeg',
      color: 'F85B66',
      name: 'Pav - Advisor',
      to: 'https://twitter.com/0xPavlo',
      from: 'Esth a.k.a G-Paw, a robot and a veteran gamer, one of the founders that lead socials, marketing strategy, and operational. Been 2 years in Crypto and NFT space as creator, collector, trader and 10+ years of experience on interactive digital media and video game industry.',
    },
    {
      src: '/about/team3.jpeg',
      color: 'F9A844',
      name: 'Thuan - Artist',
      to: 'https://twitter.com/ThuanETH',
      from: 'Anime lovers, passionate with art and has been working for more than 9 years in the creative fields. Currently, I focus my energy on founding Libero as my special project. I hope you love my art! Silence means work!',
    },
    {
      src: '/about/team4.jpeg',
      color: 'A65FD0',
      name: 'Gibbon - Artist',
      to: 'https://twitter.com/Gibbon_82',
      from: 'Kid, a Psycho Cat Community Manager. Make some concepts for the sake of community. Engineering Manager for some years and professional programmer for 7+ years experience. I hate manual work, so I made Cyborg Libero to help community grow.',
    },
    {
      src: '/about/team5.jpeg',
      color: 'ADCC64',
      name: 'Herman - Head Mod',
      to: 'https://twitter.com/hermansensei',
      from: 'Im the one who create some video, motion graphic, and a little bit animation in this team, experienced in creative industries in 10+ years and have been working with vary clients.',
    },
    {
      src: '/about/team6.jpeg',
      color: 'ADCC64',
      name: 'Fonso - Head Strategist & Collaboration',
      to: 'https://twitter.com/fonsonftt',
      from: 'Im the one who create some video, motion graphic, and a little bit animation in this team, experienced in creative industries in 10+ years and have been working with vary clients.',
    },
  ];
  return (
    <Box w={{ lg: '90%', xl: '76%', '2xl': '45%' }}>
      <Flex
        mb={{ base: '10px', md: '20px', lg: '30px' }}
        w="100%"
        justifyContent="center"
        position="relative"
      >
        <Image
          w={{ base: '230px', md: '250px', xl: '280px' }}
          h={{ base: '100px', xl: '120px' }}
          alt=""
          src="/header/woodframe.png"
        />
        <Text
          top={{ base: '34px', md: '32px', xl: '38px' }}
          position="absolute"
          fontSize={{ base: '24px', md: '26px', xl: '32px' }}
          fontWeight="700"
          color="#FFF"
        >
          TEAM
        </Text>
      </Flex>
      <Grid
        templateColumns={{
          base: 'repeat(2, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
        }}
        gap={{ base: 5, lg: 6, xl: 10 }}
      >
        {listitem.map((item, idx) => (
          <GridItem key={idx}>
            <Flex flexDir="column" alignItems="center" justifyContent="center">
              <Image
                border="1px solid rgba(0,0,0,0.75)"
                boxShadow="0px 0px 10px 0px rgba(0,0,0,0.75)"
                objectFit="cover"
                borderRadius="8px"
                w={{ base: '184px', md: '166', lg: '200px', xl: '262px' }}
                h={{ base: '184px', md: '166', lg: '200px', xl: '262px' }}
                alt=""
                src={item.src}
              />
              <Link
                display="flex"
                justifyContent="center"
                to={item.to}
                target="_blank"
                isExternal
              >
                <Flex
                  flexDir="column"
                  mt="10px"
                  w="100%"
                  alignItems="center"
                  cursor="pointer"
                  justifyContent="center"
                >
                  <Text
                    textAlign="center"
                    mr="10px"
                    mb="2px"
                    fontSize={{ base: '0.75rem', lg: '1.25rem' }}
                    fontWeight="700"
                  >
                    {item.name}
                  </Text>
                  <Box cursor="pointer">
                    <Image
                      alt=""
                      w="26px"
                      h="26px"
                      src="/header/twitter.jpeg"
                      borderRadius="10px"
                      objectFit="cover"
                    />
                  </Box>
                </Flex>
              </Link>
            </Flex>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
}
