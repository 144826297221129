export default function Icon5() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current w-6"
        fill="none"
        viewBox="0 0 148 131"
      >
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M66.486 2.211C61.14 4.828 59.99 9.352 60.13 27.236c.094 11.957-.185 15.235-.971 11.431-5.356-25.92-14.823-35.153-26.086-25.441-3.677 3.171-3.74 3.472-3.694 17.686l.046 14.459-3.11-6.24c-13.71-27.512-33.072-3.33-22.581 28.202 1.097 3.3 2.969 10.198 4.159 15.329 6.383 27.53 22.46 43.975 46.106 47.159 16.269 2.191 17.925-4.547 1.784-7.257-16.574-2.783-27.57-10.849-35.066-25.725C17.51 90.475 7.333 51.675 7.333 45.814c0-16.062 9.975-12.04 16.058 6.475 8.95 27.239 22.177 33.537 16.677 7.941C34.395 33.83 34.8 18 41.148 18c4.199 0 6.805 5.481 10.296 21.653 6.766 31.351 20.402 36.073 17.23 5.968C65.899 19.283 67.436 6.886 73.2 9.098c2.544.976 6.132 8.641 6.132 13.099 0 7.208 6.964 31.868 9.362 33.151 5.07 2.714 6.32-.673 10.708-28.981 3.852-24.855 13.461-21.331 13.541 4.966.094 31.026-3.606 39.892-18.635 44.657-12.09 3.833-21.642 10.339-21.642 14.741 0 4.677 4.038 4.24 11.179-1.211 3.43-2.618 9.56-5.479 14.75-6.884 7.67-2.076 9.707-3.329 14.942-9.187 9.943-11.127 20.82-15.891 25.529-11.182 2.444 2.444 2.137 3.006-5.733 10.518-5.292 5.05-9.082 10.497-13.614 19.569-7.206 14.421-15.79 23.329-24.847 25.783-3.047.825-6.29 1.714-7.206 1.973-2.411.684-2.05 5.827.477 6.797 9.787 3.755 29.128-11.792 38.515-30.962 4.869-9.943 7.698-13.917 13.422-18.859 15.773-13.618 3.567-30.581-15.832-22l-6.143 2.717.947-4.235c2.335-10.444 1.093-41.847-1.81-45.733-9.408-12.597-23.265-2.77-25.44 18.041-1.104 10.562-2.497 10.284-4.386-.876C84.08 5.291 76.67-2.775 66.486 2.211zM57.01 75.372c-8.394 4.194-14.33 9.217-9.011 7.626 1.467-.439 7.167-2.418 12.667-4.398 6.693-2.409 12.8-3.627 18.47-3.683 7.332-.072 8.15-.319 6.08-1.834-4.646-3.397-19.19-2.217-28.206 2.289z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </>
  );
}
