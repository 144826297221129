export default function Icon7() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current w-6"
        fill="none"
        viewBox="0 0 94 64"
      >
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M34 1.988c-7.547 3.28-3.948 13.718 5.552 16.102 6.368 1.599 4.577 6.515-3.821 10.488-12.01 5.682-19.532 6.909-26.364 4.3-7.486-2.859-9.034-2.787-9.034.425 0 3.619 5.068 6.623 12.832 7.606 6.202.785 6.656 1.131 9.835 7.485 10.541 21.068 35.142 20.672 47.854-.769 3.644-6.148 5.194-7.631 8-7.654 6.619-.056 14.813-4.797 14.813-8.572 0-4.212-2.255-4.339-8.774-.493-4.479 2.643-5.664 2.78-13.09 1.508-18.905-3.238-28.128-10.942-18.083-15.103C61.894 13.925 64.763 3.778 58.247 1.3 53.256-.597 39.018-.193 34 1.988zm23.119 46.309c4.28 1.95 4.055 3.229-1.119 6.384-8.975 5.472-27.577.664-20.419-5.277 4.835-4.012 14.115-4.489 21.538-1.107z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </>
  );
}
